:root {
	--skycolor: skyblue;
	--day-length: 86400s;
	--start-time: 0s;
	overflow: hidden;
}

.App {
	-webkit-animation: skycolors var(--day-length) linear var(--start-time)
		infinite;
	animation: skycolors var(--day-length) linear var(--start-time) infinite;
	width: 100vw;
	height: 100vh;
}

.Sun {
	background-color: rgb(255, 255, 118);
	width: 100px;
	height: 100px;
	border-radius: 100%;
	position: absolute;
	bottom: -100px;
	-webkit-animation: riseAndSet var(--day-length) linear var(--start-time)
		infinite;
	animation: riseAndSet var(--day-length) linear var(--start-time) infinite;
}

.Moon {
	background-color: rgb(223, 223, 223);
	width: 100px;
	height: 100px;
	border-radius: 100%;
	position: absolute;
	bottom: -100px;
	-webkit-animation: riseAndSet var(--day-length) linear
		calc(var(--start-time) + 12 * 3600s) infinite;
	animation: riseAndSet var(--day-length) linear
		calc(var(--start-time) + 12 * 3600s) infinite;
}

.Ground {
	background-color: green;
	width: 100vw;
	height: 5vh;
	position: absolute;
	bottom: 0;
}

@-webkit-keyframes riseAndSet {
	0% {
		bottom: -100vh;
		left: 50vw;
	}
	25% {
		bottom: -100px;
		left: 0;
	}
	50% {
		bottom: calc(100vh - 100px);
		left: calc(50vw - 50px);
	}
	75% {
		bottom: -100px;
		left: calc(100vw - 100px);
	}
	100% {
		bottom: -100vh;
		left: 50vw;
	}
}

@keyframes riseAndSet {
	0% {
		bottom: -100vh;
		left: 50vw;
	}
	25% {
		bottom: -100px;
		left: 0;
	}
	50% {
		bottom: calc(100vh - 100px);
		left: calc(50vw - 50px);
	}
	75% {
		bottom: -100px;
		left: calc(100vw - 100px);
	}
	100% {
		bottom: -100vh;
		left: 50vw;
	}
}

@-webkit-keyframes skycolors {
	0% {
		background-color: #0c1445;
	}
	25% {
		background-color: rgb(38, 89, 109);
	}
	50% {
		background-color: skyblue;
	}
	75% {
		background-color: rgb(38, 89, 109);
	}
	100% {
		background-color: #0c1445;
	}
}

@keyframes skycolors {
	0% {
		background-color: #0c1445;
	}
	25% {
		background-color: #75d5e3;
	}
	50% {
		background-color: #cdf9ff;
	}
	75% {
		background-color: #75d5e3;
	}
	100% {
		background-color: #0c1445;
	}
}

@-webkit-keyframes animateCloud {
	0% {
		margin-left: -1000px;
	}
	100% {
		margin-left: 100%;
	}
}

@keyframes animateCloud {
	0% {
		margin-left: -1000px;
	}
	100% {
		margin-left: 100%;
	}
}

.cloud {
	position: absolute;
	width: 200px;
	-webkit-filter: drop-shadow(1px 1px gray);
	filter: drop-shadow(1px 1px gray);
}
